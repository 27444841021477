import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { formats, modules } from './utils/utils';
import MultiTagInput from '../comman/MultipleCreate';
import { blogCreate, blogUpdate } from '../service/api';
import PreviewPage from './PreviewPage';
import 'react-quill/dist/quill.snow.css';
import { setOption } from '../redux/ManageSlice';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../comman/loader';
import axios from 'axios';

const CreateProduct = ({ editData, setEditData }) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [imageChange, setImageChange] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const loginData = localStorage.getItem('user');
  const allData = JSON.parse(loginData);
  const id = allData?.id;

  useEffect(() => {
    const CodeBlock = ReactQuill.Quill.import('formats/code');
    CodeBlock.className = 'ql-code';
    ReactQuill.Quill.register(CodeBlock, true);
  }, []);

  const handlePreviewClick = () => {
    const previewData = {
      title: title,
      description: value,
      tags: tags,
      image: image,
    };

    setPreviewData(previewData);
    setShowPreview(true);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageChange(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setImage(file);
    }
  };

  useEffect(() => {
    if (editData) {
      setValue(editData?.description);
      setTitle(editData?.title);
      setTags(editData?.tags || []);
      setImage(editData?.image[0]?.url);
    }
  }, [editData]);

  const handleSave = async () => {
    if (image && title && value !== '' && tags) {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', value);
      tags?.map((tag, index) => formData.append(`tags[${index}]`, tag));
      formData.append('image', image);

      let API = editData
        ? blogUpdate(editData?._id, formData)
        : blogCreate(id, formData);

      try {
        setLoading(true);
        const response = await API;

        setImage(null);
        setEditData(null);
        setTitle('');
        setValue('');
        setTags([]);
        dispatch(setOption('manage'));

        if (editData) {
          toast.success('Blog Updated successfully!');
        } else {
          toast.success('Blog Created successfully!');
        }

        // Trigger revalidation
        await fetch(`${process.env.REACT_APP_REVALIDATE_URL}/api/revalidate-blog/`);

      } catch (error) {
        toast.error('Error creating blog entry');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please fill in all fields');
    }
  };



  return (
    <>
      <ToastContainer />
      {loading && <Loader />}
      {!showPreview ? (
        <div className="min-w-[90%] bg-white shadow-lg p-3 rounded-md max-w-[500px]">
          <div className="">
            <h1 className="m-0 p-0 text-2xl font-bold">
              {editData ? 'Update Blog' : 'Blog Create'}
            </h1>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
              <div
                className="relative p-5 bg-slate-50 h-36 border-dashed border-2 border-slate-200 rounded-md flex justify-center items-center cursor-pointer"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                {image ? (
                  <div className="relative w-fit m-auto">
                    <img
                      src={
                        editData && !imageChange
                          ? image
                          : URL.createObjectURL(image)
                      }
                      alt="Uploaded"
                      className="h-[110px] w-auto  rounded-md"
                    />
                    <div
                      className="bg-black/50 p-1 rounded-full w-fit absolute -top-2 -right-2"
                      onClick={() => setImage(null)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512.021 512.021"
                        width="10"
                        height="10"
                        className="fill-white"
                      >
                        <g>
                          <path d="M301.258,256.01L502.645,54.645c12.501-12.501,12.501-32.769,0-45.269c-12.501-12.501-32.769-12.501-45.269,0l0,0   L256.01,210.762L54.645,9.376c-12.501-12.501-32.769-12.501-45.269,0s-12.501,32.769,0,45.269L210.762,256.01L9.376,457.376   c-12.501,12.501-12.501,32.769,0,45.269s32.769,12.501,45.269,0L256.01,301.258l201.365,201.387   c12.501,12.501,32.769,12.501,45.269,0c12.501-12.501,12.501-32.769,0-45.269L301.258,256.01z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Filled"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                      className="fill-slate-400 m-auto mb-2"
                    >
                      <circle cx="16" cy="8.011" r="2.5" />
                      <path d="M23,16a1,1,0,0,0-1,1v2a3,3,0,0,1-3,3H17a1,1,0,0,0,0,2h2a5.006,5.006,0,0,0,5-5V17A1,1,0,0,0,23,16Z" />
                      <path d="M1,8A1,1,0,0,0,2,7V5A3,3,0,0,1,5,2H7A1,1,0,0,0,7,0H5A5.006,5.006,0,0,0,0,5V7A1,1,0,0,0,1,8Z" />
                      <path d="M7,22H5a3,3,0,0,1-3-3V17a1,1,0,0,0-2,0v2a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z" />
                      <path d="M19,0H17a1,1,0,0,0,0,2h2a3,3,0,0,1,3,3V7a1,1,0,0,0,2,0V5A5.006,5.006,0,0,0,19,0Z" />
                      <path d="M18.707,17.293,11.121,9.707a3,3,0,0,0-4.242,0L4.586,12A2,2,0,0,0,4,13.414V16a3,3,0,0,0,3,3H18a1,1,0,0,0,.707-1.707Z" />
                    </svg>
                    <p className="text-slate-400 m-0 p-0">
                      Click Here Upload Image Or Drag Here
                    </p>
                    <input
                      type="file"
                      className="absolute cursor-pointer w-full h-full top-0 left-0 right-0 bottom-0 opacity-0"
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </div>
                )}
              </div>
              <input
                type="text"
                placeholder="Enter Title"
                className="w-full h-14 bg-white border-2 border-slate-200 rounded-md px-4 outline-none"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <MultiTagInput tags={tags} setTags={setTags} />
              <ReactQuill
                className="rounded-md"
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={setValue}
              />

              <div className="flex gap-2 justify-end">
                {image !== '' && title !== '' && value !== '' && tags !== '' ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit min-w-40"
                    onClick={handlePreviewClick}
                  >
                    Preview
                  </button>
                ) : null}

                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit min-w-40"
                  onClick={handleSave}
                >
                  {editData ? 'Update' : 'Publish'}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: 'inherit' }} className="w-full">
          <div className="w-full bg-white py-5 mb-10 px-5 flex justify-between">
            <div onClick={() => setShowPreview(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="40"
                height="40"
                className="cursor-pointer"
              >
                <path d="M19,11H9l3.29-3.29a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0l-4.29,4.3A2,2,0,0,0,6,12H6a2,2,0,0,0,.59,1.4l4.29,4.3a1,1,0,1,0,1.41-1.42L9,13H19a1,1,0,0,0,0-2Z" />
              </svg>
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit min-w-40 flex gap-2 items-center justify-center"
              onClick={handleSave}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Filled"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="fill-white"
              >
                <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.222,7H8.778A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7Zm.8,2a10.211,10.211,0,0,1,.476,3,10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2,12a9.986,9.986,0,0,1,.461-3H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461A9.986,9.986,0,0,1,2,12Zm1.353,5H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm5.425,0h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm5.827,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM22,12a9.986,9.986,0,0,1-.461,3H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437A9.986,9.986,0,0,1,22,12ZM17.426,7a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7Z" />
              </svg>
              <span>Publish</span>
            </button>
          </div>
          <PreviewPage
            previewData={previewData}
            showPreview={showPreview}
            imageChange={imageChange}
          />

        </div>
      )}
    </>
  );
};

export default CreateProduct;
